import React from 'react'

import { Link } from 'gatsby'

import QuieroInvertir from '../images/quiero_invertir.png'
import BuscoVivienda from '../images/busco_vivienda.png'

import '../styles/invierte.scss'

export default function Invierte() {
	return (
		<React.Fragment>
			<div className="invierte__title" id="invierte">
				<div className="invierte__texture" />
				<h1>INVIERTE CON NOSOTROS</h1>
			</div>
			<div className="invierte__content">
				<div className="left">
					<img src={QuieroInvertir} alt="Quiero Invertir Grupo Insula" />
					<Link to="/">
						QUIERO <br /> INVERTIR
					</Link>
				</div>
				<div className="right">
					<img src={BuscoVivienda} alt="Busco Vivienda Grupo Insula" />
					<Link to="/proyectos/insula-living">
						BUSCO <br /> VIVIENDA
					</Link>
				</div>
			</div>
		</React.Fragment>
	)
}
